import wrapWithProviderBrowser from './src/boot/wrap-with-provider-browser'
import wrapWithProviderPageElement from './src/boot/wrap-with-page-element'

export const wrapRootElement = wrapWithProviderBrowser
export const wrapPageElement = wrapWithProviderPageElement

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }
}
