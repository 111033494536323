import React from 'react'
import { IntlProvider } from 'react-intl'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, props }: any) => {
  const messages = props?.pageContext?.configuration?.translations ?? {}
  const locale = props?.pageContext?.locale ?? 'fr'

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      {element}
    </IntlProvider>
  )
}
